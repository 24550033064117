import { ThemeOptions } from "@material-ui/core";


declare module "@material-ui/core/styles/createPalette" {
    interface TypeBackground {
      grey: React.CSSProperties['color'];
    }
  }


const LightTheme: ThemeOptions = {
    palette: {
        primary: {
            main: "#c94e2f",
        },
        secondary: {
            main: "#74BCDD",
             contrastText: "#fff",
        },
        background: {
            grey: '#f0f0f0',
        },
    },
    typography: {
        h1: {
            fontSize: '3rem',
            // '@media (min-width:600px)': {
            //   fontSize: '1.5rem',
            // },
        },
        h2: {
            fontSize: '2.2rem',
        },
        h3: {
            fontSize: '1.8rem',
        },
        h4: {
            fontSize: '1.5rem',
        },
        h5: {
            fontSize: '1rem',
        },
        h6: {
            fontSize: '0.8rem',
        },
    },
  }

export default LightTheme;

