import { ThemeOptions } from "@material-ui/core";
import LightTheme from "./light";

const DarkTheme: ThemeOptions = {
    palette: {
        primary: {
            main: "#c94e2f",
        },
        secondary: {
            main: "#009EE0",
        },
        type: "dark",
        background: {
            grey: "#212121"
        }
    },
    typography: LightTheme.typography,
  }

export default DarkTheme;