/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import { ThemeProvider, CssBaseline, useMediaQuery, createMuiTheme } from "@material-ui/core";
import LightTheme from "./src/theme/light";
import DarkTheme from "./src/theme/dark";

require("prismjs/plugins/command-line/prism-command-line.css");
require("./src/styles/prism-theme.css");

const ThemeWrapper = ({children}) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const newTheme = React.useMemo(
        () =>
          createMuiTheme(prefersDarkMode ? DarkTheme : LightTheme),
        [prefersDarkMode],
      );

      return     <ThemeProvider theme={newTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
}

// You can delete this file if you're not using it
export const wrapRootElement = ({ element }) => (
    <ThemeWrapper>
        {element}
    </ThemeWrapper>
)